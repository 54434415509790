<template>
  <Form/>
</template>

<script>
import Form from '@/views/testimonials/Form'
export default {
  name: 'Create',
  components: { Form }
}
</script>
